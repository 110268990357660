.g-sidenav-pinned .sidenav-toggler {
  cursor: pointer;

  .sidenav-toggler-inner {
    &:before {
      transform: scale(1);
    }
  }

  .sidenav-toggler-line {
    background-color: theme-color("primary]");

    &:first-child {
      width: 13px;
      transform: translateX(5px);
    }

    &:last-child {
      width: 13px;
      transform: translateX(5px);
    }
  }
}
