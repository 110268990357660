//
// Icon shape
//

.icon-shape {
  padding: 12px;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  i,
  svg {
    font-size: 1.25rem;
  }

  &.icon-lg {
    i,
    svg {
      font-size: 1.625rem;
    }
  }

  &.icon-sm {
    i,
    svg {
      font-size: 0.875rem;
    }
  }

  &.icon-xs {
    i,
    svg {
      font-size: 0.6rem;
    }
  }

  svg {
    width: 30px;
    height: 30px;
  }
}

@each $color, $value in $theme-colors {
  .icon-shape-#{$color} {
    @include icon-shape-variant(theme-color($color));
  }
}
