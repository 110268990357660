
// // Custom Theming for Angular Material
// // For more information: https://material.angular.io/guide/theming
// // @use '@angular/material' as mat;
// // Plus imports for other components in your app.

// // Include the common styles for Angular Material. We include this here so that you only
// // have to load a single css file for Angular Material in your app.
// // Be sure that you only ever include this mixin once!
// // @include mat.core();

// // Define the palettes for your theme using the Material Design palettes available in palette.scss
// // (imported above). For each palette, you can optionally specify a default, lighter, and darker
// // hue. Available color palettes: https://material.io/design/color/
// $argon-dashboard-pro-angular-primary: mat.define-palette(mat.$indigo-palette);
// $argon-dashboard-pro-angular-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// // The warn palette is optional (defaults to red).
// $argon-dashboard-pro-angular-warn: mat.define-palette(mat.$red-palette);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $argon-dashboard-pro-angular-theme: mat.define-light-theme((
//   color: (
//     primary: $argon-dashboard-pro-angular-primary,
//     accent: $argon-dashboard-pro-angular-accent,
//     warn: $argon-dashboard-pro-angular-warn,
//   )
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// // @include mat.all-component-themes($argon-dashboard-pro-angular-theme);

// /* You can add global styles to this file, and also import other style files */

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.multiselect-wrapper{
    width: 100% !important;
}

.fc-view-harness{
    height: inherit;
}

.calendar{
    height: inherit;
}

.fc-daygrid-event-harness{
    cursor: pointer;
}

  /* Importing Bootstrap SCSS file. */
//@import '~bootstrap/scss/bootstrap';
