//
// Map
//

.map-canvas {
  position: relative;
  width: 100%;
  height: $map-height;
  border-radius: $border-radius;
}
